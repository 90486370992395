<template>
  <div>
    <!-- 接收后台返回的wxid type token值 -->
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  created() {},
  mounted() {
    const type = decodeURIComponent(this.$route.query.type);
    const token = decodeURIComponent(this.$route.query.token);
    const wxid = decodeURIComponent(this.$route.query.wxid);
    const page = decodeURIComponent(this.$route.query.page);
    if (token) {
      window.sessionStorage.setItem("token", token);
    }
    if (wxid) {
      window.sessionStorage.setItem("wxid", wxid);
    }
    if (!page) {
      this.page = 1;
    }
    if (page === "2") {
      this.$router.push({ path: "/myClient" });
    } else if (page === "3") {
      if (type === "1") {
        this.$router.push({ path: "/myClient" });
      } else if (type === "2") {
        this.$router.push({ path: "/myFollow" });
      }
    } else {
      switch (type) {
        case "0":
          this.$router.push({ path: "/register" });
          break;
        case "1":
          this.$router.push({ path: "/manager-system" });
          break;
        case "2":
          this.$router.push({ path: "/followPerson" });
          break;
        case "3":
          this.$router.push({ path: "/platform" });
          break;
        default:
          this.$router.push({ path: "/registerSuccess", query: { type: 1 } });
          break;
      }
    }
  },
};
</script>
<style lang="scss" scoped></style>